<template>
    <div class="image-gallery">
        <media-header />
        <div class="gallery faded-content-for-list-update">
            <div class="first-nav gallery-nav">
                <image-gallery-item
                    v-for="item in firstLine"
                    :id="item.id"
                    :item="item"
                    :key="item.id"
                />
            </div>
            <div class="second-nav gallery-nav">
                <image-gallery-item
                    v-for="item in secondLine"
                    :id="item.id"
                    :item="item"
                    :key="item.id"
                />
            </div>
            <div class="third-nav gallery-nav">
                <image-gallery-item
                    v-for="item in thirdLine"
                    :id="item.id"
                    :item="item"
                    :key="item.id"
                />
            </div>
        </div>

        <p v-if="firstLine.length == 0" class="msg">Photos not Found</p>

        <paginate
            v-model="activePage"
            :page-count="pageCount"
            v-if="firstLine.length > pageCount"
            container-class="pagination"
            :page-range="15"
            :prev-text="prev"
            :next-text="next"
            :click-handler="paginationClickCallback"
        >
            <span slot="breakViewContent">
                <svg width="16" height="4" viewBox="0 0 16 4">
                    <circle fill="#999999" cx="2" cy="2" r="2" />
                    <circle fill="#999999" cx="8" cy="2" r="2" />
                    <circle fill="#999999" cx="14" cy="2" r="2" />
                </svg>
            </span>
        </paginate>
    </div>
</template>

<script>
import MediaHeader from "@/components/custom/MediaHeader";
import ImageGalleryItem from "@/components/custom/ImageGalleryItem";
import { mapGetters } from "vuex";
import first from "lodash/first";
import Paginate from "vuejs-paginate";
import getDataList from "@/mixins/getDataList";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    mixins: [getDataList],
    components: {
        MediaHeader,
        ImageGalleryItem,
        Paginate
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        images() {
            let data = this.pagingDataList || this.data;

            return data?.list.map(item => {
                let images = item?.images?.map(image => ({
                    path: image?.devices?.[this.device?.type],
                    id: image.id
                }));

                return {
                    images,
                    cover_image: first(images)?.path,
                    date: item?.date,
                    length: images?.length,
                    title: item?.title,
                    teaser: item?.teaser,
                    color: item?.frame_color,
                    id: item?.id
                };
            });
        },
        firstLine() {
            let images = this.images?.filter(
                (image, index) => (index + 1) % 3 === 1
            );

            if (this.device?.size < 1025) {
                images = this.images?.filter(
                    (image, index) => (index + 1) % 2 === 1
                );
            }
            if (this.device?.size < 769) {
                images = this.images;
            }
            return images;
        },
        secondLine() {
            let images = this.images?.filter(
                (image, index) => (index + 1) % 3 === 2
            );

            if (this.device?.size < 1025) {
                images = this.images?.filter(
                    (image, index) => (index + 1) % 2 === 0
                );
            }

            return images;
        },
        thirdLine() {
            return this.images?.filter((image, index) => (index + 1) % 3 === 0);
        }
    }
};
</script>

<style lang="scss" scoped>
.image-gallery /deep/ {
    $ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

    .gallery {
        display: flex;
        align-items: flex-start;
        margin: -100px 225px 0;

        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            margin: -80px 165px 0;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            margin: -60px 128px 0;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            margin: -60px 47px 0;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            margin: -55px 47px 0;
        }
        @media only screen and (max-width: 767px) {
            margin: 0 16px 0;
        }

        .gallery-nav {
            display: flex;
            flex-direction: column;
            flex: 33%;
            margin-left: 30px;

            &:first-child {
                margin-left: 0;
            }

            @media only screen and (max-width: 1024px) {
                &.third-nav {
                    display: none;
                }
            }
            @media only screen and (max-width: 768px) {
                &.second-nav {
                    display: none;
                }
            }

            .gallery-image {
                margin-top: 30px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    .msg {
        margin-top: 150px;
        font-size: 21px;
    }
    .pagination {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: 144px auto 0;
        list-style-type: none;

        li {
            margin: 0 10px;
            font-weight: normal;
            font-size: 34px;
            text-align: left;
            color: #0a141c;
            transition: all 0.6s $ease-out;

            &.active {
                color: #93272c;
            }
            a {
                outline: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                -webkit-tap-highlight-color: transparent;
            }

            .next {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
</style>
